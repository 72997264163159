<template>
  <div>
    <employee-type-add-new
      :is-add-new-employee-type-sidebar-active.sync="isAddNewEmployeeTypeSidebarActive"
      @refresh-data="() => { refEmployeeTypeList.refreshData() }"
    />
    <list
      ref="refEmployeeTypeList"
      sort-by="tipoEmpleadoId"
      :refetch-records="fetchEmployeeTypes"
      refetch-records-name="tiposEmpleado"
      :actions="actions"
      key-field="tipoEmpleadoId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteEmployeeType"
      :filters.sync="filters"
    />
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'
import EmployeeTypeAddNew from './EmployeeTypeAddNew.vue'

export default {
  components: {
    EmployeeTypeAddNew,
    List,

  },
  setup() {
    /* Refs */
    const refEmployeeTypeList = ref(null)
    const isAddNewEmployeeTypeSidebarActive = ref(false)
    /* Services */
    const { fetchEmployeeTypes, fetchEmployeeType, deleteEmployeeType } = humanResources()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('humanResources.employeeType.columns.employeeType'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.employeeType.columns.description'),
        key: 'descripcion',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'TipoEmpleado',
        routeName: 'apps-human-resources-employee-type-edit',
        params: ['tipoEmpleadoId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'TipoEmpleado',
        params: ['tipoEmpleadoId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('humanResources.employeeType.list.newEmployeeTypeAction'),
        aclAction: 'create',
        click: () => { isAddNewEmployeeTypeSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      // Refs y Data
      isAddNewEmployeeTypeSidebarActive,
      refEmployeeTypeList,
      tableColumns,
      tableActions,
      actions,
      filters,
      // API Calls
      fetchEmployeeTypes,
      fetchEmployeeType,
      deleteEmployeeType,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
